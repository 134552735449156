html {
  font-size: 62.5%;
}
* {
  font-family: TradeGothicLTPro, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

body {
  font-size: 1.4rem;
  line-height: 1.5;
  letter-spacing: 0.5px;
  font-weight: 400;
  color: #222;
  background-color: #f5f5f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.center-items {
  display: flex;
  justify-content: center;
  align-items: center;
}

html {
  font-size: 62.5%;
}

.footerLogoIcon {
  display: block;
  max-width: 100%;
  margin-bottom: 0.5rem;
}
.footerBSIcon {
  display: block;
  max-width: 100%;
}

@font-face {
  font-family: "TradeGothicLTPro";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/39AB41_0_0.woff") format("woff2"),
    url("./fonts/39AB41_0_0.woff") format("woff"),
    url("./fonts/39AB41_0_0.ttf") format("truetype");
}

@font-face {
  font-family: "TradeGothicLTPro";
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/39AB41_1_0.woff") format("woff2"),
    url("./fonts/39AB41_1_0.woff") format("woff"),
    url("./fonts/39AB41_1_0.ttf") format("truetype");
}
* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-family: TradeGothicLTPro, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.hero-container {
  align-items: center;
  height: 100%;
  background-position: center center;
  background-size: cover;
  transition: all 0.1s ease-out;
}

body {
  font-size: 1.4rem;
  line-height: 1.5;
  letter-spacing: 0.5px;
  font-weight: 400;
  color: #222;
  background-color: #f5f5f5;
}

.is-mobile-form {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-row-gap: 10px;
}

.is-normal-size-form {
  display: grid;
  grid-template-columns: 30% 30% 30% 10%;
}

.splide__list {
  transition: transform 500ms ease 0s;
}

.splide__track {
  transition: height 500ms;
}

.splide__pagination__page {
  font-size: 0 !important;
  color: transparent !important;
  width: 3rem !important;
  height: 4px !important;
  cursor: pointer !important;
  border: none !important;
  border-radius: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  background-color: #d8d8d8;
}

.splide__pagination__page.is-active {
  background-color: #fcbb00 !important;
  transform: none !important;
}

.splide__pagination li {
  font-size: 0 !important;
  display: inline-block !important;
  margin: 0 3px !important;
  padding: 0 !important;
  border: 0 !important;
}

.grecaptcha-badge {
  display: none;
}

@media screen and (max-width: 992px){
  .is-pinned {
    top: 10rem!important;
  }
}

@media screen and (min-width: 767px){
  .is-pinned {
    padding: 2rem 1rem 0.5rem;
    background-color: #222;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
    position: fixed;
    border-radius: 4.5rem;
    margin: 0 auto;
    left: 50%;
    top: 10rem;
    transform: translateX(-50%);
    width: calc(100% - 1.5rem * 4);
  }
}

@media screen and (max-width: 575px) {
  .is-highlighted > article {
    transform: unset !important;
    box-shadow: unset !important;
  }
}

.is-highlighted > article {
  transform: scale(1.1);
  background-color: #f5f5f5;
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 25%);
}

.is-highlighted > h3 {
  color: #ffb800;
}

.is-highlighted a {
  background-image: linear-gradient(169deg, #f3b706 0%, #f29505 100%);
}

.is-highlighted .amount-per-month {
  background: #ffb800;
}

.is-highlighted-in-hp > div {
  background-image: linear-gradient(169deg, #f3b706 0%, #f29505 100%);
  z-index: 2;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
  padding: 3rem 4rem 7rem;
  width: calc(100% + 6rem);
  margin-left: -3rem;
  margin-right: -3rem;
}

.is-highlighted-in-hp h2,
.is-highlighted-in-hp div,
.is-highlighted-in-hp h1,
.is-highlighted-in-hp p {
  color: #fff;
}

.is-highlighted-in-hp > * {
  color: #fff;
  background-image: none;
}

.is-highlighted-in-hp .amount-month {
  background-image: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 35%, rgba(255,255,255,1) 100%);

}

.is-highlighted-in-hp .amount-month small,
.is-highlighted-in-hp .amount-month p {
  color: #767474;
}

.is-highlighted-in-hp a {
  background: #fff;
}

.current-header-tab {
  border-bottom: 3px solid #FCBB00;
  padding-bottom: 0.5rem;
}

/*region Blocchi Trasparenza*/
@media screen and (min-width: 769px){
  .trasparenza-attachments-buttons-container h4 {
    font-size: 1.6rem !important;
  }
}
@media screen and (max-width: 576px){
  .trasparenza-attachments-buttons-container div {
    text-align: center!important;
  }
}
@media screen and (max-width: 576px){
  .trasparenza-attachments-buttons-container article {
    text-align: center!important;
    grid-template-columns: 1fr!important;
    grid-template-rows: 1fr 1fr 1fr 1fr!important;
    justify-items: center;
  }
}

.trasparenza-attachments-buttons-container {
}
.trasparenza-attachments-buttons-container h4 {
  display: block;
  margin-block: 1.33em;
  margin-inline: 0px;
  margin: 0px 0px 0.75em;
  padding: 0px;
  border: 0px;
  line-height: 1.2;
  font-weight: 700;
  font-size: 1.2rem;
  letter-spacing: 0.25px;
}
.trasparenza-attachments-buttons-container a {
  cursor: pointer;
  min-height: 3.6rem;
  min-width: 0px;
  font-weight: 700;
  text-decoration: none;
  line-height: 1;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.1s ease-out;
  font-size: 1.3rem;
  background-color: #fcbb00;
  border-radius: 2.3rem;
  padding: 1rem 3rem;
  letter-spacing: 1px;
}
.trasparenza-attachments-buttons-container a:hover {
  box-shadow: 0 3px 18px 0 rgb(45 46 46 / 15%);
}
.trasparenza-attachments-buttons-container span {
  padding-top: 0.25em;
  transition: all 0.1s ease-out 0s;
  color: rgb(34, 34, 34);
  white-space: nowrap;
}
.trasparenza-attachments-buttons-container div {
  width: 100%;
  text-align: initial;
}
.trasparenza-attachments-buttons-container div {
  /* text-align: center; */
  padding: 3rem 15px 1.5rem;
}

.trasparenza-attachments-container {
  padding: 3rem 15px 1.5rem!important;
  flex: 0 0 100%!important;
  max-width: 100%!important;
  position: relative!important;
  width: 100%!important;
  text-align: initial!important;
}
.trasparenza-attachments-container a {
  color: initial!important;
}

.trasparenza-attachments-container h1 {
  text-align: initial !important;
  margin: .75em 0 !important;
  padding: 0 !important;
  border: 0 !important;
  line-height: 1.2 !important;
  font-weight: 700 !important;
  letter-spacing: 1px !important;
  font-size: 4rem !important;
}

.trasparenza-attachments-container h2 {
  text-align: initial !important;
  margin: 0px 0px 0.75em !important;
  padding: 0px !important;
  border: 0px !important;
  line-height: 1.2 !important;
  font-weight: 700 !important;
  letter-spacing: 1px !important;
  font-size: 3rem !important;
}
.trasparenza-attachments-container h3 {
  text-align: initial!important;
  font-size: 2rem!important;
  letter-spacing: 0.5px!important;
  line-height: 1.2!important;
  font-weight: 700!important;
  margin-bottom: 0.75em!important;
  padding: 0px!important;
  border: 0px!important;
  margin-top: 15px!important;
}
.trasparenza-attachments-container h4 {
  display: block !important;
  margin-block: 1.33em !important;
  margin-inline: 0px !important;
  margin: 0px 0px 0.75em !important;
  padding: 0px !important;
  border: 0px !important;
  line-height: 1.2 !important;
  font-weight: 700 !important;
  font-size: 1.2rem !important;
  letter-spacing: 0.25px !important;
}
.trasparenza-attachments-container ul {
  list-style: disc;
  padding-left: 3rem;
  margin-bottom: 1em;
}
.trasparenza-attachments-container li {
  text-align: initial!important;
}
/*endregion*/

/*region Articoli*/
.article-container img {
  width: 100% !important;
  height: auto !important;
}
/*endregion Articoli*/


























